import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { SocialMediaIcon, TransitionHero, Media } from 'components/ui';
import { Serif, Sans } from 'Typography';
import sc from 'config/theme';
import breakpoint from 'config/responsive';
import bp from 'config/responsive';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../animContact.css";



const Container = styled.div`
  width: 100vw;
  background: rgba(${sc.colors.main_rgb}, 0.2);
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 70px;


  & .metaLabel {
    text-transform: uppercase;
    margin: 15px 0;
  }
  & .metaContent {
    font-size: 20px ;
  }
`;

const MetaContainer = styled.div`
  padding: 30px 30px 50px;

  a {
    color: #fff;
    text-decoration: none;
  }


`;

const LocationName = styled.div`



`;

const FlexComponent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 7;

  ${Sans} {
    display: block;
  }

`;


const ContactImage = styled.div`
  /*style for contact image*/
  padding: 30px;
  background-color: #1a2b4c;
  height: 40vh;
  min-height: 215px;
   background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;

  ${bp.smallNotebook} {
	  height: 75vh;
  }

`

const ContactItem = styled.div`
  /*style for contact container*/

  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`



const SocialMediaContainer = styled.div`
  display: flex;

  & a:first-child {
    margin-left: 0;


  }
`;

const MetaDetails = styled.div`
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
`;

class ContactPage extends PureComponent {
  state = {
    data: [],
    office: {
      address : "",
      area : "",
      email : "",
      fb : "",
      ig : "",
      li : "",
      phone : "",
      tag : "",
      twitter: "",
      src: "",

    }
  };

  handleMouseOver = office => {

    this.setState({ office });
  }

  componentDidMount() {
    var  { locations } = this.props.data.pages.contact;

    this.setState({
      data: locations.map(office => ({
        ...office,
      })),



      office: locations[1],
    });
  }

  render() {
    const { data } = this.state;


    console.log(this.state.office.src);
    return (
      <Layout noFooter>
        <SEO title={this.props.data.pages.contact.seo.title} description={this.props.data.pages.contact.seo.meta} keywords={this.props.data.pages.contact.seo.keywords} />

        <Container>


            {this.state.office &&
              data.map(office => (



                  <React.Fragment key={office.tag}>

                    <ContactItem>
                      <ContactImage src={office.src}>

                       <Serif color="white" alpha align="center">
                          {office.area}
                        </Serif>
                      </ContactImage>

                      <MetaContainer>
                          <MetaDetails>
                            <Sans color="white" delta weight="medium" className="metaLabel">
                              Address
                            </Sans>
                            <Sans color="white" eta weight="bold" className="metaContent">
                              {office.address}
                            </Sans>
                          </MetaDetails>

                          <MetaDetails>
                            <Sans color="white" delta weight="medium" className="metaLabel">
                              Get in touch
                            </Sans>
                            <Sans color="white" eta weight="bold" className="metaContent">
                              <a href={`tel:${office.phone}`}>{office.phone}</a>
                            </Sans>
                          </MetaDetails>
                          <MetaDetails>
                            <Sans color="white" delta weight="medium" className="metaLabel">
                              Email us
                            </Sans>
                            <Sans color="white" eta weight="bold" className="metaContent">
                                <a href={`mailto:${office.email}`}>{office.email}</a>
                            </Sans>
                          </MetaDetails>

                          <SocialMediaContainer>
                            <SocialMediaIcon
                              name="facebook"
                              href={office.fb}
                              title="Like us on Facebook"
                            />
                            <SocialMediaIcon
                              name="linkedin"
                              href={office.li}
                              title="Check our LinkedIn"
                            />
                            <SocialMediaIcon
                              name="twitter"
                              href={office.twitter}
                              title="Follow us on Twitter"
                            />
                            <SocialMediaIcon
                              name="instagram"
                              href={office.ig}
                              title="Follow us on Instagram"
                            />
                          </SocialMediaContainer>
                        </MetaContainer>
                      </ContactItem>

                  </React.Fragment>


              ))




            }

        </Container>
      </Layout>
    );
  }
}

export default ContactPage;

export const query = graphql`
  query ContactPage {

    pages {
      contact {
        locations {
          address
          phone
          email
          fb
          li
          twitter
          ig
          area
          tag
          src
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;
